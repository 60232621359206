import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { filter, Subject, take, takeUntil } from 'rxjs';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { NotificationsComponent } from '@notifications/components/notifications/notifications.component';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NotificationsStoreService } from '@notifications/store/services/notifications-store.service';
import { RouterStoreService } from '@core-layout/app/store/services/router-store.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { OnMarketStoreService } from '@on-market/store/services/on-market-store.service';
import { BADGE_COUNT_ALERT_LIMIT, NOTIFICATION_TYPE_NOTIFICATION_BELL_TAB_INDEX_MAP } from '@notifications/constants/notifications.constants';
import { ListingCommunicationsService } from 'app/modules/listing-modules/listing-communications/listing-communications-base/services/listing-communications.service';

type NavigationItem = 'portfolio' | 'search' | 'landing' | 'appointments' | 'notifications';

@Component({
    selector: 'navigation-panel',
    templateUrl: './navigation-panel.component.html',
    styleUrls: ['./navigation-panel.component.scss'],
})
export class NavigationPanelComponent implements OnInit {
    public activeItem: NavigationItem | null = null;
    public readonly canSearchForListings$ = this.settingsStoreService.canSearchForListings$;
    public readonly unviewedNotificationTotalCount$ = this.notificationsStoreService.unviewedNotificationTotalCount$;
    public readonly badgeCountAlertLimit = BADGE_COUNT_ALERT_LIMIT;

    private readonly unsubscribe$ = new Subject<void>();
    constructor(
        private readonly routeService: RouteService,
        private readonly bottomSheet: MatBottomSheet,
        private readonly routerStoreService: RouterStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly onMarketStoreService: OnMarketStoreService,
        private readonly listingCommunicationsService: ListingCommunicationsService
    ) { }

    public ngOnInit(): void {
        this.routerStoreService.url$.pipe(
            filter(Boolean),
            takeUntil(this.unsubscribe$)
        ).subscribe(url => this.activeItem = this.getNavigationItemToActivate(url));

        this.listingCommunicationsService.openNotificationBellTab$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ notificationType }) => {
                const activeTabIndex = NOTIFICATION_TYPE_NOTIFICATION_BELL_TAB_INDEX_MAP.get(notificationType);

                this.notificationsStoreService.setActiveTabIndex(activeTabIndex);

                this.onNotificationBellClicked();
            });
    }

    public onNotificationBellClicked(): void {
        if (this.bottomSheet._openedBottomSheetRef != null) {
            return;
        }

        const config = { panelClass: ['bottom-panel-wrap', 'rpc-notifications-bottomsheet'] };

        const bottomSheetRef = this.bottomSheet.open(NotificationsComponent, config);

        bottomSheetRef.instance.notificationSourceNavigated
            .pipe(take(1))
            .subscribe((notificationNavigateParams) => this.onNotificationSourceNavigated(notificationNavigateParams));

        bottomSheetRef.instance.closed
            .pipe(take(1))
            .subscribe(() => bottomSheetRef.dismiss());

        bottomSheetRef.afterDismissed()
            .pipe(take(1))
            .subscribe(() => this.onNotificationBellClosed());
    }

    public onAppointmentsClicked(): void {
        this.onNavigationItemChanged('appointments', RpcRoute.Appointments);
    }

    public onPortfolioClicked(): void {
        this.onNavigationItemChanged('portfolio', RpcRoute.Portfolio);
    }

    public onSearchClicked(): void {
        this.onNavigationItemChanged('search', RpcRoute.SearchListings);
    }

    public onHomeClicked(): void {
        this.onNavigationItemChanged('landing', RpcRoute.FindHome, () => this.onMarketStoreService.resetState());
    }

    private onNavigationItemChanged(navigationItem: NavigationItem, routeToNavigate: RpcRoute, action?: () => void) {
        if (this.activeItem === navigationItem) {
            return;
        }

        this.activeItem = navigationItem;

        if (action != null) {
            action();
        }

        this.routeService.navigate(routeToNavigate).catch(() => { });
    }

    private onNotificationSourceNavigated(notificationNavigateParams: NotificationNavigateParams): void {
        this.bottomSheet.dismiss();

        this.notificationsStoreService.setActiveTabIndex(null);
        this.notificationsStoreService.redirectToNotificationEntity(notificationNavigateParams);
    }

    private onNotificationBellClosed(): void {
        this.notificationsStoreService.setActiveTabIndex(null);
    }

    private getNavigationItemToActivate(url: string): NavigationItem {
        const pageNameUrlSegment = url.split('/')[1] as RpcRoute;

        const isListingPage = pageNameUrlSegment === RpcRoute.Listing.split('/')[0];

        const pageName = isListingPage
            ? this.routeService.lastManuallySavedNavigatedRoute ?? RpcRoute.Portfolio
            : pageNameUrlSegment;

        return this.mapUrlToNavigationItem(pageName);
    }

    private mapUrlToNavigationItem(pageName: RpcRoute): NavigationItem {
        switch (pageName) {
            case RpcRoute.SharedListing:
            case RpcRoute.Portfolio: return 'portfolio';
            case RpcRoute.Appointments: return 'appointments';
            case RpcRoute.SearchListings: return 'search';
            case RpcRoute.Profile:
            case RpcRoute.AdditionalProfile:
            case RpcRoute.KnowledgeBase:
            case RpcRoute.FindHome: return 'landing';
            default: return null;
        }
    }
}
