<div class="profile-edit-address">

    <div class="profile-edit-address-item" *ngFor="let addressControl of addressControls; let i = index">

        <div class="profile-edit-address-item-header">

            <div class="profile-edit-address-item-header-title">
                {{ 'ADDRESS_EDIT_FORM.TITLES.ADDRESS' | translate }} {{ i + 1 }}
            </div>

            <rpc-button class="profile-edit-address-item-header-btn" [color]="'warn'"
                [title]="'ADDRESS_EDIT_FORM.TITLES.DELETE'" [iconName]="'delete-bin'" (clicked)="onRemove(i)">
            </rpc-button>

        </div>

        <div class="profile-edit-address-item-body">

            <rpc-select class="profile-edit-address-item-select profile-edit-address-item--full" [color]="'accent'"
                [label]="'ADDRESS_EDIT_FORM.TITLES.TYPE'" [controlOptions]="addressControl.selectTypeOptions"
                [appearance]="'fill'">
            </rpc-select>

            <rpc-input class="profile-edit-address-item-input profile-edit-address-item--full"
                [label]="'ADDRESS_EDIT_FORM.TITLES.ADDRESS_LINE_1'" [appearance]="'fill'" type="text"
                [options]="addressControl.line1InputOptions">
            </rpc-input>

            <rpc-input class="profile-edit-address-item-input profile-edit-address-item--full"
                [label]="'ADDRESS_EDIT_FORM.TITLES.ADDRESS_LINE_2'" [appearance]="'fill'" type="text"
                [options]="addressControl.line2InputOptions">
            </rpc-input>

            <rpc-input class="profile-edit-address-item-input profile-edit-address-item--city"
                [label]="'ADDRESS_EDIT_FORM.TITLES.CITY'" [appearance]="'fill'" type="text"
                [options]="addressControl.cityInputOptions">
            </rpc-input>

            <rpc-select class="profile-edit-address-item-select profile-edit-address-item--state" [color]="'accent'"
                [label]="'ADDRESS_EDIT_FORM.TITLES.STATE'" [appearance]="'fill'"
                [controlOptions]="addressControl.selectStateOptions"
                [disabled]="addressControl.selectCountryOptions.selectedValue != defaultCountryCode">
            </rpc-select>

            <rpc-input class="profile-edit-address-item-input profile-edit-address-item--zip"
                [label]="'ADDRESS_EDIT_FORM.TITLES.ZIP_CODE'" [appearance]="'fill'" type="text"
                [options]="addressControl.zipCodeInputOptions">
            </rpc-input>

            <rpc-select class="profile-edit-address-item-select profile-edit-address-item--country" [color]="'accent'"
                [label]="'ADDRESS_EDIT_FORM.TITLES.COUNTRY'" [appearance]="'fill'"
                (changed)="onCountryChanged($event, i)" [controlOptions]="addressControl.selectCountryOptions">
            </rpc-select>

        </div>

    </div>

    <div class="profile-edit-address-add-wrap">

        <rpc-button [color]="'accent'" [iconName]="'add'" [title]="'ADDRESS_EDIT_FORM.TITLES.ADD_NEW'"
            (clicked)="onAdd()">
        </rpc-button>

    </div>
</div>