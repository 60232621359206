<div class="profile-settings">

    <div class="profile-settings-section">

        <div class="profile-settings-section-title">
            {{ 'PROFILE.TITLES.NOTIFICATIONS' | translate }}
        </div>

        <email-alert-settings class="profile-settings-section-block" [settings]="settings"
            (settingsChanged)="onSettingsChanged($event)">
        </email-alert-settings>

    </div>

    <mat-divider class="profile-settings-divider">
    </mat-divider>

    <div class="profile-settings-section">

        <div class="profile-settings-section-title">
            {{ 'PROFILE.TITLES.ACCESS' | translate }}
        </div>

        <div class="profile-settings-section-block">

            <div class="profile-settings-section-box">

                <div class="profile-settings-section-box-title">
                    {{ 'PROFILE.TITLES.CHANGE_PASSWORD' | translate }}
                </div>

                <password class="profile-settings-section-box-inner" [isAdditionalProfile]="!isSelfProfile"
                    (passwordChanged)="onPasswordChanged($event)">
                </password>

            </div>

            <div class="profile-settings-section-box">

                <div class="profile-settings-section-box-title">
                    {{ 'PROFILE.TITLES.ACCOUNT' | translate }}
                </div>

                <div class="profile-settings-section-box-inner">

                    <rpc-button class="deactivate" [mainButton]="true" [color]="'warn'" [appearanceType]="'stroke'"
                        [title]="'PROFILE.TITLES.DEACTIVATE_ACCOUNT'" (clicked)="onDeactivateAccount()">
                    </rpc-button>

                </div>

            </div>

        </div>

    </div>

    <ng-container *ngIf="isSelfProfile">

        <mat-divider class="profile-settings-divider">
        </mat-divider>

        <div class="profile-settings-section">

            <div class="profile-settings-section-title">
                {{ 'PROFILE.TITLES.OTHER' | translate }}
            </div>

            <div class="profile-settings-section-block">

                <mat-slide-toggle class="theme-toggle" [labelPosition]="'before'"
                    [checked]="settings?.layoutSettings?.darkMode" (change)="onDarkModeSelected($event.checked)">

                    <span class="theme-toggle-box">

                        <span class="theme-toggle-text">
                            {{ 'PROFILE.TEXT.DARK_MODE' | translate}}
                        </span>

                        <span class="theme-toggle-note">
                            {{ 'PROFILE.TEXT.DARK_MODE_NOTE' | translate}}
                        </span>

                    </span>

                </mat-slide-toggle>

            </div>

        </div>

    </ng-container>

</div>