<form [formGroup]="changePasswordForm" class="change-password-wrap">

    <div *ngIf="!isAdditionalProfile" class="change-password-item">

        <rpc-input [type]="'password'" [appearance]="'fill'" [label]="'PASSWORD.FIELDS.OLD_PASSWORD'"
            [autocomplete]="'current-password'" [options]="oldPasswordControlOptions">
        </rpc-input>

    </div>

    <div class="change-password-item">

        <rpc-input [type]="'password'" [appearance]="'fill'" [label]="'PASSWORD.FIELDS.NEW_PASSWORD'"
            [autocomplete]="'new-password'" [options]="newPasswordControlOptions">
        </rpc-input>

    </div>

    <div class="change-password-item">

        <rpc-input [type]="'password'" [appearance]="'fill'" [label]="'PASSWORD.FIELDS.PASSWORD_CONFIRM'"
            [autocomplete]="'new-password'" [options]="confirmPasswordControlOptions">
        </rpc-input>

    </div>

    <div class="change-password-btn-wrap">

        <rpc-button class="change-password-btn" [mainButton]="true" [color]="'accent'" [appearanceType]="'flat'"
            [disabled]="isUpdateButtonDisabled"
            [title]="isAdditionalProfile ? 'PASSWORD.BUTTONS.UPDATE_PASSWORD' : 'PASSWORD.BUTTONS.CHANGE_PASSWORD'"
            (clicked)="save()">
        </rpc-button>

    </div>

</form>