import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AgentsModule } from '@agents/agents.module';
import { RpcControlsModule } from '@core-controls/controls.module';
import { ScrollToTopModule } from '@core-layout/scroll-to-top/scroll-to-top.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcInfoModule } from '@core-utils/rpc-info/rpc-info.module';
import { UsersModule } from '@users/users.module';
import { AddressesEditFormComponent } from './components/addresses-edit-form/addresses-edit-form.component';
import { AddressesListComponent } from './components/addresses-list/addresses-list.component';
import { EmailAlertSettingsComponent } from './components/email-alert-settings/email-alert-settings.component';
import { InviteUserDialogComponent } from './components/invite-user-dialog/invite-user-dialog.component';
import { InviteUserFormComponent } from './components/invite-user-form/invite-user-form.component';
import { NewMatchSettingsComponent } from './components/new-match-settings/new-match-settings.component';
import { PasswordComponent } from './components/password/password.component';
import { PhonesEditFormComponent } from './components/phones-edit-form/phones-edit-form.component';
import { PhonesListComponent } from './components/phones-list/phones-list.component';
import { ProfileTopComponent } from './components/profile-top/profile-top.component';
import { WelcomeVideoLinkComponent } from './components/welcome-video-link/welcome-video-link.component';
import { locale as english } from './i18n/en';
import { ProfileBaseEffects } from './store/effects/profile-base.effects';
import { profileBaseReducer } from './store/reducers/prifile-base.reducer';
import { ProfileToolbarComponent } from './components/profile-toolbar/profile-toolbar.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { EmailsEditFormComponent } from './components/emails-edit-form/emails-edit-form.component';
import { EmailsListComponent } from './components/emails-list/emails-list.component';
import { EditProfileInfoComponent } from './components/edit-profile-info/edit-profile-info.component';
import { GeneralInfoFormComponent } from './components/general-info-form/general-info-form.component';
import { GeneralInfoPageComponent } from './pages/general-info-page/general-info-page.component';
import { GeneralInfoTabComponent } from './components/general-info-tab/general-info-tab.component';
import { ProfileSettingsComponent } from './components/profile-settings/profile-settings.component';
import { ProfileSettingsPageComponent } from './pages/profile-settings-page/profile-settings-page.component';
import { NewMatchSettingsPageComponent } from './pages/new-match-settings-page/new-match-settings-page.component';

const components = [
    AddressesListComponent,
    PhonesListComponent,
    AddressesEditFormComponent,
    PhonesEditFormComponent,
    ProfileTopComponent,
    PasswordComponent,
    NewMatchSettingsComponent,
    EmailAlertSettingsComponent,
    InviteUserFormComponent,
    InviteUserDialogComponent,
    WelcomeVideoLinkComponent,
    ProfileToolbarComponent,
    GeneralInfoComponent,
    EmailsListComponent,
    EmailsEditFormComponent,
    EmailsListComponent,
    EditProfileInfoComponent,
    GeneralInfoFormComponent,
    GeneralInfoTabComponent,
    GeneralInfoPageComponent,
    ProfileSettingsComponent,
    ProfileSettingsPageComponent,
    NewMatchSettingsPageComponent
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ScrollToTopModule,
        FormsModule,
        ReactiveFormsModule,
        NgLetModule,

        MatDividerModule,
        MatRadioModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatInputModule,

        AgentsModule,
        RpcIconModule,
        RpcControlsModule,
        UsersModule,
        RpcInfoModule,

        // Module Configuration
        StoreModule.forFeature('profile-base', profileBaseReducer),
        EffectsModule.forFeature([ProfileBaseEffects])
    ],
    declarations: [components],
    exports: [components]
})
export class ProfileBaseModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
